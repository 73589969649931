import {
  chartData,
  tableData,
  getKpiCode,
  isAddAvg,
} from "../NationalPerformanceReport/kpiCode.js";
import { UnitProcessing } from "@/until/option";
import underscore from "underscore";
import moment from "moment";
import { percentage, ThousandYuan } from "@/until/optionPrint";

const areaData = [
  { title: "平均单车销售水平事业毛利", kpiCodes: ["A0105072"], val: 0, isAvg: false },
  { title: "平均单车售后水平事业毛利", kpiCodes: ["B0105134"], val: 0, isAvg: false },
  { title: "销售水平事业毛利占销售业务毛利比", kpiCodes: ["A0107021"], val: 0, isAvg: false },
];

const columns = [
  { key: "name", title: "指标名称", dataIndex: "name", scopedSlots: { customRender: "name" } },
  { key: "Unit", title: "单位", dataIndex: "Unit" },
  { key: "Current", title: "当月值", dataIndex: "Current" },
  { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
  { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
  { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
  { key: "YearAvg", title: "年度店均", dataIndex: "YearAvg" },
  { key: "CurrentYear", title: "年度累计", dataIndex: "CurrentYear" },
];
const columnsAvg = [
  { key: "name", title: "指标名称", dataIndex: "name", scopedSlots: { customRender: "name" } },
  { key: "Unit", title: "单位", dataIndex: "Unit" },
  { key: "Current", title: "当月值", dataIndex: "Current" },
  { key: "MarchAverage", title: "前三月平均", dataIndex: "MarchAverage" },
  { key: "LastYear", title: "去年同期", dataIndex: "LastYear" },
  { key: "IndexRange", title: "指标范围", dataIndex: "IndexRange" },
  { key: "CurrentYear", title: "年度店均", dataIndex: "CurrentYear" },
];

const tableStoreData = tableData;

function getTableDataCode(Data) {
  let arr = [];
  Object.values(Data).forEach(table => {
    table.table.forEach(kpi => {
      kpi.kpiCodes.forEach(kpiCode => {
        arr.push(kpiCode);
      });
    });
  });
  return arr;
}

const kpiCode = {
  monthRankCodes: [],
  yearRankCodes: [],
  monthPushCodes: [...getTableDataCode(chartData)],
  richCodes: [...getKpiCode(areaData), ...getTableDataCode(tableStoreData)],
  monthStandardPushCodes: [],
};

function getAreaData(richData) {
  areaData.forEach(d => {
    let kpiCode = isAddAvg(d, 0);
    Object.keys(richData).forEach(code => {
      if(code === kpiCode) {
        if(code === "A0107021") {
          d.val = percentage(richData[code].Current);
        } else {
          d.val = ThousandYuan(richData[code].Current);
        }
      }
    });
  });
}

function getUnitTableValue(paramData, carSelect, tableName) {
  tableName.data = [];
  tableName.table.forEach(tableValue => {
    let Arr = {};
    Arr.name = tableValue.title;
    let index = carSelect.carKey;
    if(tableValue.kpiCodes.length < 5) index = 0; // 处理无车型选
    let kpiCode = tableValue.kpiCodes[index];
    Object.keys(paramData).forEach(d => {
      if(d === kpiCode) {
        let myData = paramData[kpiCode];
        if(tableValue.Unit !== undefined) {
          Arr.Unit = tableValue.Unit;
        } else {
          Arr.Unit = myData.Unit;
        }
        Arr.Current = UnitProcessing(Arr.Unit, myData.Current || 0);
        Arr.MarchAverage = UnitProcessing(Arr.Unit, myData["PreThreeMonthAvg"] || 0);
        Arr.LastYear = UnitProcessing(Arr.Unit, myData.LastYear || 0);
        Arr.IndexRange = "-";
        Arr.CurrentYear = tableValue.YearSum ? tableValue.YearSum : UnitProcessing(Arr.Unit, myData.YearSum || 0);
        Arr.YearAvg = UnitProcessing(Arr.Unit, myData.YearAvg || 0);
      }
    });
    tableName.data.push(Arr);
  });
}

function getTableData(richData, carSelect) {
  Object.keys(tableStoreData).forEach(tableName => {
    getUnitTableValue(richData, carSelect, tableStoreData[tableName]);
  });
}

function getChartMonthValue(chartValue, carSelect, chartName, m, d) {
  if(d === "日期") return;
  let kpiCode = "";
  chartName.table.forEach(tableValue => {
    if(tableValue.title === d) {
      let index = carSelect.carKey;
      if(tableValue.kpiCodes.length < 5) index = 0; // 处理无车型选
      kpiCode = tableValue.kpiCodes[index];
    }
  });
  if(chartValue[kpiCode] === undefined) return null;
  if(m === "月平均") {
    return chartValue[kpiCode]["MonthAvg"] === 0 ? null : chartValue[kpiCode]["MonthAvg"];
  }
  return chartValue[kpiCode][m] === 0 ? null : chartValue[kpiCode][m]; // 返回 NULL 不显示数字
}

function getElapseMonth(chartValue, carSelect, chartName) {
  chartName.rows = [];
  let date = moment(carSelect.ReportDate).add(-12, "M");
  let months = underscore.range(12).map(() => date.add(1, "M").format("M"));
  months.push("月平均");
  months.forEach(m => {
    let arr = {};
    chartName.columns.forEach(d => {
      if(m === "月平均") {
        arr["日期"] = m;
      } else {
        arr["日期"] = m + "月";
      }
      arr[d] = getChartMonthValue(chartValue, carSelect, chartName, m, d);
    });
    chartName.rows.push(arr);
  });
}

function GetCharData(chartValue, carSelect) {
  Object.keys(chartData).forEach(chartName => {
    getElapseMonth(chartValue, carSelect, chartData[chartName]);
  });
}

export {
  kpiCode, areaData, columns, columnsAvg, tableStoreData,
  getAreaData, getTableData, GetCharData,
};
