<template>
  <a-row>
    <a-col>
      <a-card title="1、销售整体水平事业">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableOne"/>
        <chartComponent :chart-data="chartData.chartOne"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="2、售后整体水平事业(未区分车型)">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableTwo"/>
        <chartComponent :chart-data="chartData.chartTwo"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="3、销售水平事业-新车用品">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableThere"/>
        <chartComponent :chart-data="chartData.chartThere"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="4、销售水平事业-金融按揭">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableFour"/>
        <chartComponent :chart-data="chartData.chartFour"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="5、销售水平事业-新车保险">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableFive"/>
        <chartComponent :chart-data="chartData.chartFive"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="6、销售水平事业-上牌验车">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableSix"/>
        <chartComponent :chart-data="chartData.chartSix"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="7、销售水平事业-新车延保">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableSeven"/>
        <chartComponent :chart-data="chartData.chartSeven"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="8、销售水平事业-销售自主服务项目">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableEight"/>
        <chartComponent :chart-data="chartData.chartEight"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="9、销售水平事业-置换业务(未区分车型)">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableNine"/>
        <chartComponent :chart-data="chartData.chartNine"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="10、售后水平事业-售后续保(未区分车型)">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableTen"/>
        <chartComponent :chart-data="chartData.chartTen"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="11、售后水平事业-非新车延保(未区分车型)">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableEleven"/>
        <chartComponent :chart-data="chartData.chartEleven"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="12、售后水平事业-售后自主服务项目(未区分车型)">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableTwelve"/>
        <chartComponent :chart-data="chartData.chartTwelve"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="13、二手车(未区分车型)">
        <a-my-table :columns-data="columns" :table-data="tableStoreData.tableThirteen"/>
        <chartComponent :chart-data="chartData.chartThirteen"/>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { columns, tableStoreData, getTableData, GetCharData, columnsAvg } from "./storeKpiCode.js";
import aMyTable from "@/views/monthReport/shopAnalysis/component/aTable";
import chartComponent from "@/components/chartComponent";
import { chartData } from "../NationalPerformanceReport/kpiCode.js";

export default {
  name: "stormPerformance",
  components: {
    aMyTable, chartComponent,
  },
  props: {
    allData: {
      type: Object,
    },
    dataParams: {},
  },
  data() {
    return {
      columns, columnsAvg,tableStoreData, chartData,
      active: 0,
    };
  },
  mounted() {
  },
  methods: {},
  watch: {
    allData: {
      handler: function(val) {
        if(Object.keys(val).length > 0)
          getTableData(val["Rich"], this.dataParams);
          GetCharData(val["MonthPush"], this.dataParams);
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/views/monthReport/analysisReport/childComponent/storeManagment.less";

.chart-border {
  margin-top: 30px;
  }

.ant-col {
  padding: 4px 0 !important;
  }
</style>
