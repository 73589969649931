<template>
  <a-row>
    <a-col v-if="isCloseA">
      <a-anchor :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
        <div class="fix_border">
          <a-col :span="20">
            月份：
            <a-month-picker style="width: 150px;" placeholder="请选择报告月份" @change="dateChange"
                :disabledDate="disabledDate" v-model="ReportDateMoment" :allowClear="false"
            />
            销售店：
            <a-cascader style="width: 240px" :options="dealers" :allowClear="false"
                placeholder="请选择/输入销售店" v-model="choseDealer" @change="dealerChange"
                :show-search="true"
            />
            车型:
            <a-select v-model="params.carName" @change="carNameChange" style="width:130px;">
              <a-select-option v-for="(item,carIndex) in carTypes" :key="carIndex" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col style="float: right;text-align: right">
                    <span style="float: right;position: relative;top: -12px;margin-left:1vw" @click="isCloseA=false">
                        <a-icon type="close"/>
                    </span>
            <a-tooltip placement="top" :title="`点击生成《${ moment(this.ReportDateMoment).format('yyyy年MM月')+this.params.DealerName + this.params.carName }经营业绩分析报告》`">
              <a-button type="primary" @click="generateImages()">生成长图</a-button>
            </a-tooltip>
          </a-col>
        </div>
      </a-anchor>
    </a-col>
    <div class="" ref="capture">
      <a-col>
        <a-card>
          <div class="report_bg">BDM业绩分析报告(水平事业 - {{ params.carName }}) <br>
            <span style="font-size: 22px;font-weight: 500"> {{
                params.DealerName + moment(params.ReportDate).format(" yyyy年MM月 ") }}</span>
          </div>
          <a-descriptions bordered :column="3" style="padding-top: 10px">
            <a-descriptions-item v-for="item of areaData" :key="item.kpiCodes[0]">{{ item.val }}
              <div slot="label">
          <span v-if="item.title==='销售水平事业毛利占销售业务毛利比'">
            <a-tooltip placement="top" title="占比超100%及以上，考虑新车（一级+二级）毛利负数" arrow-point-at-center>{{ item.title }} <a-icon type="question-circle"/></a-tooltip>
            </span>
                <span v-else>{{ item.title }}</span>
              </div>
            </a-descriptions-item>
          </a-descriptions>
        </a-card>
        <stormPerformance :all-data.sync="allData" :data-params="params"/>
      </a-col>
    </div>
  </a-row>
</template>

<script>
import { disabledDate } from "@/until/dayFormat";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";
import moment from "moment";
import { carTypes } from "@/views/performanceReport/NationalPerformanceReport/kpiCode";
import { areaData, getAreaData, kpiCode } from "./storePerformance/storeKpiCode.js";
import stormPerformance from "@/views/performanceReport/storePerformance/stormPerformance";

export default {
  name: "storePerformanceReport",
  components: {
    stormPerformance,
  },
  data() {
    return {
      isCloseA: true, disabledDate, moment, carTypes,
      params: {
        ReportDate: moment().add(-1, "M").format("YYYY-MM-01"),
        DealerCode: "",
        DealerName: "",
        carKey: 0,
        carName: "所有车型",
      },
      areaData,
      dealerInfo: {},
      dealerMap: {},
      dealers: [],
      choseDealer: [],
      allData: {},
      chartData: {},
    };
  },
  async created() {
    await this.getDealersByRole();
  },
  methods: {
    // 日期框点击方法
    async dateChange(date, dateString) {
      this.params.ReportDate = dateString + "-01";
      await this.getData();
    },
    async dealerChange(value, val) {
      this.params.DealerCode = val[1].value;
      this.params.DealerName = val[1].label;
      await this.getData();
    },
    async carNameChange(val, index) {
      this.params.carKey = index.key;
      await this.getData();
    },
    async getData() {
      let params = {};
      params["DealerCode"] = this.params.DealerCode;
      params["DealerName"] = this.params.DealerName;
      params["ReportDate"] = this.params.ReportDate;
      params["monthRankCodes"] = this._.uniq(kpiCode.monthRankCodes);
      params["yearRankCodes"] = this._.uniq(kpiCode.monthRankCodes);
      params["monthPushCodes"] = this._.uniq(kpiCode.monthPushCodes);
      params["richCodes"] = kpiCode.richCodes;
      params["monthStandardPushCodes"] = kpiCode.monthStandardPushCodes;
      let res = await this.$Http["DealerBusinessAnalysisReport"](params);
      if(res.Data)
        this.allData = res.Data;
      getAreaData(this.allData["Rich"]);
    },
    // 获取当前选择的经销商信息
    async getDealerInfo() {
      let res = await this.$Http.getCurrDealerInfo({
        DealerCode: this.params.DealerCode,
      });
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      this.choseDealer = this.dealerMap[res.Data.DealerCode];
      if(this.choseDealer == null) {
        this.params.DealerCode = this.dealerMap[Object.keys(this.dealerMap)[0]][1];
        if(this.params.DealerCode != null) {
          await this.getDealerInfo();
        }
        return;
      }
      this.dealerInfo = res.Data;
      this.params.DealerCode = res.Data.DealerCode;
      this.params.DealerName = res.Data.DealerName;
      await this.getData();
    },
    async getDealersByRole() {
      let res = await this.$Http.GetDealersByRole();
      if(!res["Success"]) {
        this.$message.error(res.Message);
        return;
      }
      let dealerCount = 0;
      this.dealers = res.Data.chain().map(p => {
        dealerCount += p.Dealers.length;
        let part = {};
        part.value = p.PartId;
        part.label = p.PartName;
        part.children = p.Dealers.chain().map(d => {
          let dealer = {};
          dealer.value = d.DealerCode;
          dealer.label = d.DealerName;
          this.dealerMap[dealer.value] = [part.value, dealer.value];
          return dealer;
        }).value();
        return part;
      }).value();
      await this.getDealerInfo();
    },
    generateImages() {
      message.loading("生成中,请稍后....", 4);
      setTimeout(() => {
        html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
          this.img = canvas.toDataURL();
          if(window.navigator["msSaveOrOpenBlob"]) {
            const atob1 = atob(this.img.split(",")[1]);
            let n = atob1.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = atob1.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator["msSaveOrOpenBlob"](blob, `${moment(this.ReportDateMoment).format("yyyy年M月") + this.params.DealerName + this.params.carName}经营业绩分析报告.png`);
          } else {
            const a = document.createElement("a");
            a.href = this.img;
            a.setAttribute("download", `${moment(this.ReportDateMoment).format("yyyy年M月") + this.params.DealerName + this.params.carName}经营业绩分析报告.png`);
            a.click();
          }
        }).catch((error) => {
          this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
        });
      }, 1000);
    },
  },
  computed: {
    ReportDateMoment: {
      get() {
        return moment(this.params.ReportDate);
      },
      set(newValue) {
        this.params.ReportDate = newValue.format("YYYY-MM-01");
      },
    },
  },
};
</script>

<style lang="less" scoped>
@import "./performance.less";

</style>
